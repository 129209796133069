


















import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "Login",
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    signIn(): void {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.$store.dispatch("fetchUser", result.user).then(() => {
            let redirect: string =
              (this.$route.query.redirect as string) || "/";

            this.$router.push(redirect).catch(() => {
              null;
            });
          });
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
  },
});
